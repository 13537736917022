import React from "react";
//import { Logger } from "aws-amplify";
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import saasdata from "../api/saasdata";
//import saasdatacontent from '../api/saasdatacontent';
import "../SaaS.css";
import { updateObject,checkValidity, getContentAsync } from "../shared/utility";
import Input from "../components/UI/Input/Input";
import Button from "../components/UI/Button/Button";
import Spinner from "../components/UI/Spinner/Spinner";

//const logger = new Logger("FindPassword");

class FindPassword extends React.Component {
  state = {
    controls: {
      empnum: {
        elementType: "input",
        elementConfig: {
          type: "input",
          placeholder: "",
        },
        value: "",
        validation: { required: true },
        valid: false,
        touched: false,
        label: ""
      }
    },
    loading: false,
    error: '',
    label__findpassword__instructions: 'Please enter your Employee Number (this employee number is used to find the email address that we will use to send you your new password):',
    literal__findpassword__contentarea: 'Password reminder'
  };

  async getContent(shopID,contenttypeID,isoCode,language,ctrl)
  {

      try{
          const content = await getContentAsync(shopID,contenttypeID,isoCode,language);
          if(content !== '')
            this.setState({[ctrl]: content});
      }
      catch(error) {
          console.log('Err get conententtype' + contenttypeID + ' ' + error) ;
      }  
      
  }

  componentDidMount () {
    this.getContent(this.props.shopID,6234,this.props.isoCode,this.props.language,'label__findpassword__instructions');
    this.getContent(this.props.shopID,6229,this.props.isoCode,this.props.language,'literal__findpassword__contentarea')
    //to delete was just to test ascii this.getContent(64,6229,'SE','se','literal__findpassword__contentarea')
  }

  inputChangedHandler = ( event, controlName ) => {
    const updatedControls = updateObject( this.state.controls, {
        [controlName]: updateObject( this.state.controls[controlName], {
            value: event.target.value,
            valid: checkValidity( event.target.value, this.state.controls[controlName].validation ),
            touched: true
        } )
    } );
    this.setState( { controls: updatedControls } );
}

  submitHandler = event => {
    event.preventDefault();
    const usercontent = "/user/" + this.state.controls.empnum.value;
   // let that = this;
    if (this.state.controls.empnum.valid) {
      //MCI TODO find out why I can't update state in submit
      this.setState({ loading: true });
      saasdata
        .get(usercontent)
        .then(res => {
          if (res.data.error) {
            console.log(res);
          } 
          if(res.data==='')
            //this.setState({error: "User not found!"});
            this.getContent(this.props.shopID,6233,this.props.isoCode,this.props.language,'error');
          else
            console.log(res.data);
        })
        .catch(function(error) {
          console.log("mihaela" + error);
        });
      this.setState({ loading: false });
      return true;
    } else {
      //this.setState({error: "Validation error please check!"});
      this.getContent(this.props.shopID,6230,this.props.isoCode,this.props.language,'error');
      return false;
    }
  };


  render() {
    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });

      let form = formElementsArray.map(formElement => (
        <Input
          key={formElement.id}
          label={formElement.config.label}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={event => this.inputChangedHandler(event, formElement.id)}
        />
      ));

      if (this.state.loading) {
        form = <Spinner />;
      }

      let errorMessage = null;
      if ( this.state.error ) {
          errorMessage = ReactHtmlParser(this.state.error);
      }
      return (
        <div>
          <form onSubmit={this.submitHandler}>
            <h1>{ReactHtmlParser(this.state.literal__findpassword__contentarea)}</h1>
            <div className="ErrorText">{errorMessage}</div>
            <label>
            {ReactHtmlParser(this.state.label__findpassword__instructions)}
            </label>
            {form}
            
            <Button btnType="Success">SUBMIT</Button>
            <br />
            {/*TODO delete-used only for test <a href="/auth">Back to SignIn page</a>*/}
          </form>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
      isAuthenticated: state.auth.token !== null
  };
};

export default connect( mapStateToProps )( FindPassword );

import React, { Component } from 'react';

import saasdatacontent from '../api/saasdatacontent';
import HtmlContent from '../shared/htmlcontent';
//MCITODO uncomment the ligne bellow if debug nedeed 
//import {  Logger } from 'aws-amplify';


//const logger = new Logger('Footer');

class Footer extends Component
{
    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false,
            contentValue:'',
            contentIsHTML: false
        }
    }
        

    componentDidMount() {
        //TODO Find a way to get shop and type and languages
        const shopID = 127;
        const  contentTypeID =9;
        const isoCode = 'GB';
        const language = 'en';
        //const content = '/content/' + this.state.shopID + '/' + this.state.contentTypeID + '/' + this.state.isoCode +'/' + this.state.language;
        const content = '/content/' + shopID + '/' + contentTypeID + '/' + isoCode +'/' + language;
        saasdatacontent.get(content)
        .then( res => {
            if(res.data.error) 
            {
                this.setState({
                    requestFailed: true,
                    contentIsHTML: res.data.contentIsHTML
                })
            } else { 
                this.setState({contentValue: res.data.contentValue,
                    contentIsHTML: res.data.contentIsHTML
                });
            }})
        .catch(function (error) {
            console.log('Error from get footer content'); 
        });
    }
    
    render () {
       // logger.log('Props' + this.props)
        return (
        <HtmlContent requestFailed={this.state.requestFailed} contentIsHTML={this.state.contentIsHTML} contentValue={this.state.contentValue} />);
    }
}

export default Footer;
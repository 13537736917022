import axios from 'axios';

export default axios.create({
    baseURL: 'https://5223ct1kig.execute-api.eu-west-1.amazonaws.com/Prod/api',
        //'https://5223ct1kig.execute-api.eu-west-1.amazonaws.com/Prod/api',
       // http://localhost:54287/api -local api with dynamo session',
       //http://localhost:55113/api - local api without session
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    crossDomain: true
}); 

import React, { Component } from 'react';
//import ReactHtmlParser from 'react-html-parser';
import {  Logger } from 'aws-amplify';
import { connect } from 'react-redux';
//import {  Link} from 'react-router-dom';
//import { Redirect } from 'react-router-dom';


//import saasdatacontent from '../api/saasdatacontent';
//import { setAuthRedirectPath } from '../store/actions';
//import * as actions from '../store/actions/index';
import Logo from '../components/Logo/Logo';
import '../SaaS.css'

const logger = new Logger('MENU');


class Home extends Component {
  
  componentDidMount () {
    logger.log("isAuthenticated " + this.props.isAuthenticated);
}

  render() 
  {
      
      const user = this.props.userId;
      const logoPath=this.props.logoPath;
      return (
      
      <React.Fragment>
        <h1>Home</h1>
        { user && <div>You are signed in as  {this.props.userId}</div> }
        <Logo logoPath={logoPath} />
      </React.Fragment>
        
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
    logoPath: state.main.logoPath
  }
};

const mapDispatchToProps = dispatch => {
  return {
        //MCI TODO Uncoment when we want timeout
        //  onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
        //onHomeInitContentMenu40: (content) => dispatch(actions.homeInitContentMenu40(content))
  }
}

export default connect( mapStateToProps,mapDispatchToProps ) (Home);
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter  } from 'react-router-dom';
import { Provider } from 'react-redux';
//import { reducer as formReducer} from 'redux-form';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './store/reducers/auth';
import mainReducer from './store/reducers/main';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const appReducer = combineReducers({
    auth: authReducer,
    main : mainReducer
   //form: formReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
      const { routing } = state
      state = { routing } 
    }
    return appReducer(state, action)
  }

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

//MCITODO - get title from shop object
const app = (
    <Provider store={store}>
    <BrowserRouter >
        <App appTitle="Mks"/>
    </BrowserRouter >
    </Provider>
);


ReactDOM.render(app, document.querySelector('#root'));

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
//registerServiceWorker();

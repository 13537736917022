import React from 'react';
import ReactHtmlParser from 'react-html-parser';
//, { processNodes, convertNodeToElement, htmlparser2 }

const HtmlContent = (props)=> {
    
    if (props.requestFailed || !props.contentIsHTML) return <div>{props.contentValue}</div>
    return (<div>{ ReactHtmlParser(props.contentValue)}</div>)
    } 

export default HtmlContent;
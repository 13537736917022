//import ReactHtmlParser from 'react-html-parser';

import saasdatacontent from '../api/saasdatacontent';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}


export const getContent = (shopID, contenttypeID,isoCode, language, defaultContent) =>
{
    let content = ''
    const url = '/content/' + shopID + '/' + contenttypeID + '/' + isoCode +'/' + language;
    saasdatacontent.get(url)
          .then( res => {
              if(res.data.error) 
              {
                console.log('error getting content shopID ' + shopID + ' conententtype ' + contenttypeID + 'language ' + isoCode + language, res.data.error);
                content=defaultContent;
              } else { 
                  // if(res.data.contentIsHTML)
                  //   content=ReactHtmlParser(res.data.contentValue)
                  // else 
                    content=res.data.contentValue;
                    //console.log(content);
                 
              }
            })
          .catch(function (error) {
              console.log('Err get conententtype' + contenttypeID + ' ' + error) ;
          });
          console.log('before return');
          console.log(content);
          return content;
}

export const  getContentAsync = async (shopID, contenttypeID,isoCode, language) =>
{
    const url = '/content/' + shopID + '/' + contenttypeID + '/' + isoCode +'/' + language;
    const result =  await saasdatacontent.get(url)
    if(result.data.error) 
        return '';
    const {data} = await result;
    return data.contentValue;
}
import React, { Component } from 'react';

/* Load amplify */
import Amplify , {  Logger } from 'aws-amplify';
import {  Route, Switch , withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import "./SaaS.css"


/*Local Files*/
import Layout from './hoc/Layout/Layout';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import { poolData } from './config.json';
import FindPassword from './containers/FindPassword';
import Guidelines from './containers/Guidelines'
import Home from './containers/Home';
import Logout from './containers/Logout';
import * as actions from './store/actions/index';


Amplify.Logger.LOG_LEVEL = 'INFO'; // We write INFO level logs throughout app


/* Initialize amplify */
Amplify.configure({
  Auth: poolData
});

const logger = new Logger('APP');

const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth');
});

class App extends Component {

  render () 
  {
    logger.log('User is authenticated :' + this.props.isAuthenticated);
    let routes = (
    <Switch>
      <Route path="/auth" component={asyncAuth} />";
      <Route path="/findpassword" component={FindPassword} />
      <Route path="/logout" component={Logout} />
     {/* <Route path="/assets/*" component={Header} />
      <Redirect to="/auth" />*/}
    </Switch>);
    if ( this.props.isAuthenticated ) 
    {
      routes = (
        <Switch>
              <Route path="/home" component={Home} />
              <Route path="/logout" component={Logout} />
              <Route path="/guidelines" component={Guidelines} />
        </Switch>
      )
    }
 
    

    return (
      <div>
        <link rel="stylesheet" type="text/css" href={this.props.styleCss} />
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    styleCss: state.main.styleCss
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch( actions.authCheckState() )
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );

import { Auth } from 'aws-amplify';


import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

//mci I'll use userid = user sub for instant, once the lambda authenticator will be in place
//it will be the userid from our portal_users table
export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (empnum, password) => {
    return dispatch => {
        dispatch(authStart());
        console.log('trying to login ' + empnum + ' with ' + password)
        Auth.signIn(empnum, password)
          .then(user => {
            console.log("Conected user is :" + user.username); //mci tests+ user.CognitoUser.signInUserSession)
            console.log(user);
            //MCI TO DO setup manually expiration date in 10 min, to see how I can configure it
            const expireIn = 10*60;
            const expirationDate = new Date(new Date().getTime() + expireIn * 1000);
            localStorage.setItem('token',user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', user.username);
            dispatch(authSuccess(user.signInUserSession.idToken.jwtToken,user.username));
            dispatch(checkAuthTimeout(expireIn));
          })
          .catch(err => {
            dispatch(authFail(err));
            console.error('Error loggin in auth user');
            console.info(err);
          });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }   
        }
    };
};




import ReactHtmlParser from 'react-html-parser';

import * as actionTypes from './actionTypes';
import saasdatacontent from '../../api/saasdatacontent';



export const mainInit = () => {
    return {
        type: actionTypes.MAIN_INIT,
    };
};


export const mainInitContentMenu40Success = (result) => 
{
    return {
        type: actionTypes.MAIN_INIT_CONTENTMENU40,
        contentValue: result
    };
};


export const mainInitContentMenu40Failed = (error) =>{
    return {
    type : actionTypes.MAIN_INIT_CONTENTMENU40_FAILED,
    error: error,
    requestFailed:  true
    }
}


export const mainInitContentMenu40 = (content) => {
    return dispatch => {
            // console.log('content url from home action' + content)
             saasdatacontent.get(content)
             .then( res => {
                let cnt='';
                //console.log( ReactHtmlParser(res.data.contentValue));
                if(res.data.contentIsHTML) 
                    cnt = ReactHtmlParser(res.data.contentValue)
                else
                    cnt = res.data.contentValue;
                //console.log('content get from db ');
                //console.log(cnt);
                dispatch(mainInitContentMenu40Success(cnt));
             })
             .catch( error =>  {
                dispatch(mainInitContentMenu40Failed(error));
             });
};
};
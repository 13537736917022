

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';


export const MAIN_INIT  = 'MAIN_INIT';
export const MAIN_INIT_CONTENTMENU40 = 'MAIN_INIT_CONTENTMENU40';
export const MAIN_INIT_CONTENTMENU40_FAILED = 'MAIN_INIT_CONTENTMENU40_FAILED ';

export const HOME_GETUSER= 'HOME_GETUSER';
export const HOME_INIT = 'HOME_INIT' ;



import React from 'react';

import Logo from '../../assets/images/MKS.jpg';
import  './Logo.css';

//MCITODO serch how can we loas it automatichally from shop object {`${props.logoPath}`}}
const logo = (props) => (
    <div className="Logo" >
        <img src={Logo} alt="Logo"/>
    </div>
);

export default logo;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    shopID: 91,
    isoCode: 'GB',
    language: 'en',
    styleCss: 'SaaS',
    logoPath : './assets/images/MKS.jpg',
    requestFailed : false,
    contentIsHTML : false,
    contentValue :'',
    error:null,
    menuOptionsOld : 
    {
           empQandA: 'QandA',
           empBodySoul : 'Guidelines',
           empConfirmation1 : 'Home',
           empContact : 'Contantct',
          //MCI TODO see how we will work with assets Price List href='/assets/1ac532c8-e8d2-4a12-a7a7-ff97227e7dbf-00006/documents/avis/abg_price list_v3 jan 2017.pdf
           empOrderTrackingalt : 'OrderTracking'
    }
}

const mainInit=(state,action) => {
    return (state, {requestFailed : false, contentValue:''})
};

const mainInitContentMenu40Success = (state, action) => {
    return  updateObject(state, {
        contentValue: action.contentValue,
        contentIsHTML: action.contentIsHTML
    });
};


const mainInitContentMenu40Failed = (state, action) =>{
    return updateObject( state, { error: action.error } );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.MAIN_INIT: return mainInit( state, action );
        case actionTypes.MAIN_INIT_CONTENTMENU40: return mainInitContentMenu40Success( state, action );
        case actionTypes.MAIN_INIT_CONTENTMENU40_FAILED: return mainInitContentMenu40Failed(state, action);
        default: 
            return state;
    }
};

export default reducer;
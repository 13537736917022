//import ReactHtmlParser from 'react-html-parser';

//import saasdatacontent from '../../api/saasdatacontent';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    userlabel: ''
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        error: null,
        loading: false
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        /*case actionTypes.AUTH_USERLABEL: return getUserLabel193Success(state, action);
        case actionTypes.AUTH_USERLABEL_FAILED: return getUserLabel193Failed(state, action);
        case actionTypes.AUTH_PASSWORDLABEL: return getPasswordLabel194Success(state, action);
        case actionTypes.AUTH_PASSWORDLABEL_FAILED: return getPasswordLabel194Failed(state, action);*/
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        default:
            return state;
    }
};


export default reducer;

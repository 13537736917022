import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Aux from '../_Aux/_Aux';
import  './Layout.css';
//import "../SaaS.css";
//import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';


class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState( { showSideDrawer: false } );
    }

        sideDrawerToggleHandler = () => {
            this.setState( ( prevState ) => {
                return { showSideDrawer: !prevState.showSideDrawer };
            } );
        }

        render () {
            return (
                <Aux>
                <div className="body">
                    <div className="hd">
                        <Header />
                    {/* <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler}  isAuth={this.props.isAuthenticated} />*/}
                    </div>
                    <div className="MainContainer">
                        <div className="MainMenu">
                                <SideDrawer
                                isAuth={this.props.isAuthenticated}
                                open={this.state.showSideDrawer}
                                closed={this.sideDrawerClosedHandler} />
                        </div>
                        <div className="Main">
                         <main className="Content">{this.props.children}</main>
                          {/*  {this.props.children} */}
                        </div>
                        <div className="RightBar">
                        </div>
                    </div>{/*container finished*/}
                    <div className="ft">
                        <Footer />
                    </div>
                </div>
            </Aux>
        )
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

export default connect( mapStateToProps )( Layout );
import React, { Component } from 'react';
import { connect } from 'react-redux';
    

import Logo from '../components/Logo/Logo';

class Header extends Component
{
    render () {
       // const images = require.context('../assets/images/', true);
        const logoSrc=this.props.logoPath;
       // console.log("logo path is " + logoSrc);
        return (
            <React.Fragment>
            <div>
                 <Logo logoPath={logoSrc} />
             </div> 
             </React.Fragment>);
            
    }
}


const mapStateToProps = state => {
    return {
        logoPath: state.main.logoPath 
    };
};

export default connect( mapStateToProps )( Header );
import React, { Component } from 'react';
import {  Logger } from 'aws-amplify';
import { connect } from 'react-redux';

import '../SaaS.css'
import NavigationItem from '../components/Navigation/NavigationItems/NavigationItem/NavigationItem';
import * as actions from '../store/actions/index';

const logger = new Logger('NavigationItems');
  
class  NavigationItems extends Component
{
   
  componentDidMount () {
     // logger.log("isAuthenticated " + this.props.isAuthenticated);  
      const  contentTypeID =40;
      const content = '/content/' +  this.props.shopID + '/' + contentTypeID + '/' + this.props.isoCode +'/' + this.props.language;
      this.props.onMainInitContentMenu40(content);
    }

    render()
    {
        logger.log(this.props.history);
        const options = Array.from(this.props.contentValue)
        const menuItems = options.map((option,index) =>{
        if(option.props && option.props.href && option.props.href.substring(0, 4) !=="http" && option.props.href.substring(0,7 ) !=="/assets")
        { 
        const hrefOption = option.props.href.substring(0,option.props.href.indexOf('.'));
        //logger.log(hrefOption);
        //logger.log(this.props.menuOptionsOld[hrefOption]);
        return this.props.menuOptionsOld[hrefOption] && <ul key={index} className="NavigationItem">
            <NavigationItem link={this.props.menuOptionsOld[hrefOption]}>{option.props.children}</NavigationItem>
        </ul>
        }
        return  <ul key={index} className="NavigationItem">{option}</ul>
        });
        
        return (
          <React.Fragment>
            {this.props.isAuthenticated ?  
              <ul className="NavigationItems">
                <NavigationItem link="/logout">Logout</NavigationItem> 
              </ul>:
              <ul className="NavigationItems">
              <NavigationItem link="/auth">Sign In</NavigationItem> 
              <NavigationItem link="/findpassword">Find Password</NavigationItem>
            </ul>
            }
            {this.props.isAuthenticated ? <div>{menuItems}</div> : <div></div>}
            {/*<div>{menuItemsOld}</div>*/}
          </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
  return {
     shopID: state.main.shopID,
     isoCode: state.main.isoCode,
     language: state.main.language,
     userId: state.auth.userId,
     isAuthenticated: state.auth.token !== null,
     requestFailed : state.main.requestFailed,
     contentValue : state.main.contentValue,
     error: state.main.error,
     menuOptionsOld: state.main.menuOptionsOld
  }
};

const mapDispatchToProps = dispatch => {
  return {
        //MCI TODO Uncoment when we want timeout
        //  onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path))
        onMainInitContentMenu40: (content) => dispatch(actions.mainInitContentMenu40(content))
  }
}

export default connect( mapStateToProps,mapDispatchToProps ) (NavigationItems);
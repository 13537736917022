import React, { Component } from 'react';

class Guidelines extends Component{
    render()  {
    return(
        <form>
        <div>Guidelines</div>
        </form>
    )}
}

export default Guidelines;
import axios from 'axios';

export default axios.create({
    baseURL: 'https://1up3s7ipwb.execute-api.eu-west-1.amazonaws.com/testheader/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    crossDomain: true
}); 

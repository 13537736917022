import React from 'react';
import { NavLink } from 'react-router-dom';
//TODO investigate how to use css as classes
//import classes from   './NavigationItem.css';
// activeClassName={classes.active}>{props.children}</NavLink>
import '../../../../SaaS.css'

const navigationItem = ( props ) => (
    <li className="NavigationItem">
        <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName="active">{props.children}
            </NavLink>
    </li>
);

export default navigationItem;